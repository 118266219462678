import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css"; // Import styles for the rich text editor
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactToPrint from "react-to-print";
import Select, { components } from "react-select"; // Import components from react-select
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles

function SalesByInvoice({ navigation }) {
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const cancle = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleNavigation("reports");
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const [customerData, setCustomerData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [sumOfTaxTotal, setSumOfTaxTotal] = useState("");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const jwtToken = localStorage.getItem("jwtToken");
  const [loading, setLoading] = useState(true);
  const componentRef = useRef();
  const [vendorNames, setVendorNames] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(""); // New state for vendor ID
  const [selectedVendor, setSelectedVendor] = useState({
    value: "All Details",
    label: "All Details",
  });
  useEffect(() => {
    // Get the encrypted uniqueId from session storage
    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId,vendorId:"" }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setCustomerData(Data);
          setTotalCount(data.sumOfInvoice);
          setTotalValue(data.sumOfTotal);
          setSumOfTaxTotal(data.sumOfTax);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const [itemData, setItemData] = useState({
    organizationName: "",
    companyName: "",
  });
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          // const fetchid = portfolioItem.id;
          // setId(fetchid);

          setItemData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            name: portfolioItem.name,
            role: portfolioItem.role,

            uniqueId: portfolioItem.uniqueId,
          });
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchAllVendors();
  }, []);

  const [options, setOptions] = useState([]);

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const fetchAllVendors = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const vendorOptions = data.responseMessage.map((vendor) => ({
            value: vendor.vendorDisplayName,
            label: vendor.vendorDisplayName,
          }));
          setVendorNames(data.responseMessage);
          setOptions([
            { value: "All Details", label: "All Details" },
            ...vendorOptions,
          ]);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  };
  const handleOptionChange = (selectedOption) => {
    setSelectedVendor(selectedOption);

    if (selectedOption.value === "All Details") {
      setSelectedVendorId(""); // Reset vendorId when "All Details" is selected
      fetchStatement1("");
    } else {
      const selectedVendor = vendorNames.find(
        (vendor) => vendor.vendorDisplayName === selectedOption.value
      );
      if (selectedVendor) {
        setSelectedVendorId(selectedVendor.id); // Set vendorId to the current selected vendor's ID
        fetchStatement1(selectedVendor.id);
      } else {
        
        setSelectedVendorId(""); // In case no matching vendor is found
        fetchStatement1("");
      }
    }
  };

  const [dataPost, setDataPost] = useState({
    uniqueId: decryptedUniqueId,
    vendorId:""
  });

  useEffect(() => {
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(dataPost), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setCustomerData(Data);
          setTotalCount(data.sumOfInvoice);
          setTotalValue(data.sumOfTotal);
          setSumOfTaxTotal(data.sumOfTax);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
          setCustomerData();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const fetchStatement1 = (id) => {
    fetch(BASE_URL + `/fetchInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        vendorId: id,
      }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setCustomerData(Data);
          setTotalCount(data.sumOfInvoice);
          setTotalValue(data.sumOfTotal);
          setSumOfTaxTotal(data.sumOfTax);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
          setCustomerData();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      border: "1px solid #ccc",
      borderRadius: "4px",
      paddingLeft: "6px",
      width: "100%",
      maxWidth: "400px", // Ensure responsiveness
      boxSizing: "border-box",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: "400px",
    }),
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <span className="custom-control-icon">📅</span>
      {children}
    </components.Control>
  );
  let i = 1;

  return (
    <>
      <style>
        {`
          .custom-datepicker-wrapper {
            position: relative;
            display: inline-block;
            width: 200px;
          }

          .custom-datepicker-calendar {
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          .react-datepicker__input-container {
            width: 100%;
          }

          .react-datepicker__input-container input {
            width: 100%;
            height: 40px;
            padding: 0 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }

          .custom-label {
            display: block;
            margin-bottom: 5px;
            font-size: 16px;
            color: #333;
          }

          .custom-date-picker {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        `}
      </style>

      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={cancle}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5
            className="ml-1 mb-0"
            style={{ letterSpacing: "1px", color: "#086bd5de" }}
          >
            Sales By Invoice
          </h5>
        </div>
      </div>
      <div
        className="sales-customer-section my-3 mx-0"
        style={{ padding: "30px", backgroundColor: "#fff" }}
      >
        <div className="d-flex justify-content-between mt-1 mb-1 ml-3">
          {/* <div className="d-flex align-items-center">
            <Select
              options={[
                { value: "All Details", label: "All Details" },
                ...vendorNames.map((names) => ({
                  value: names.vendorDisplayName,
                  label: names.vendorDisplayName,
                })),
              ]}
              value={selectedVendor}
              onChange={handleOptionChange}
              styles={customStyles}
              placeholder="Select a vendor"
            />
          </div> */}

          <div className="d-flex align-items-center">
            <ReactToPrint
              trigger={() => (
                <span
                  className="input-group-text"
                  id="pdf-icon"
                  style={{
                    backgroundColor: "#F6F6F6",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  📑
                </span>
              )}
              content={() => componentRef.current}
            />{" "}
          </div>
        </div>
        <div ref={componentRef} style={{ backgroundColor: "white" }}>
          <div className="container col-md-12" style={{ textAlign: "center" }}>
            <h5 style={{ fontWeight: "normal" }}>{itemData.companyName}</h5>
            <p>Basis: Accrual</p>
            <p style={{ fontWeight: "normal" }}></p>
            {customerData && customerData.length > 0 ? (
              <div className="container mt-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Invoice Date</th>
                      <th>Invoice Number</th>
                      <th>Customer Name</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerData.map((item) => (
                      <tr key={item.id}>
                        <td>{i++}</td>
                        <td>{item.invoiceDate}</td>
                        <td>{item.invoiceNumber}</td>
                        <td>{item.customerName}</td>
                        <td>{item.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="container mt-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Item Name</th>
                      <th>Unit</th>
                      <th>Available Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={4}>Item Not Found For This Vendor</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {/* <div>
          <p
            style={{
              marginTop: "20px",
              display: "inline-block",
              marginLeft: "8px",
            }}
          >
            **Amount is displayed in your base currency{" "}
            <span
              style={{
                backgroundColor: "rgb(56,138,16)",
                padding: "2px 4px",
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              INR
            </span>
          </p>
        </div> */}

        {/* <div className="mt-2">
        <button class="col-md-1 btn btn-primary btn-lg btn-block">NOTE </button>
      </div> */}
      </div>
    </>
  );
}

export default SalesByInvoice;
