import React from "react";
import { AuthURL } from "./CommonApi";
import Navbar from "../../Components/Common/Navbar";
import Leftbar from "../../Components/Common/Leftbar";
import Analytical from "./Dashboard/Analytical";
import Vendors from "./Purchases/Vendors";
import Expenses from "./Purchases/Expenses";
import Bills from "./Purchases/Bills";
import PurchaseOrder from "./Purchases/PurchaseOrders";
import BankAcc from "./Banking/BankAcc";
import newzerosblackmainmenu from "../../asset/images/zarpblackcrop.png";
import Login from "../auth/Login";
import ManageRecipe from "./ManageRecipe/ManageRecipe";
import Items from "./Shop/Items";
import ManageSalesPerson from "./Shop/ManageSalesPerson";
import ManageCategory from "./Shop/ManageCategory";
import ManageStock from "./Shop/ManageStock";
import ManageEmployee from "./Office/ManageEmployee";
import ManageServer from "./Office/ManageServer";
import ManageProject from "./Office/ManageProject";
import ManagePortfolio from "./Office/ManagePortfolio";
import ManageProjectData from "./Office/ManageProjectData";
import TimeSheetLog from "./Office/TimeSheetLog";

import Customers from "./Sales/Customers";
import Estimates from "./Sales/Estimates";
import Order from "./Sales/SalesOrder";
import Challans from "./Sales/DeliveryChallans";
import Invoices from "./Sales/Invoices";
import InvoicesNew from "./SalesNew/InvoicesNew";
import Payment from "./Sales/PaymentRecieved";
import Credit from "./Sales/CreditNotes";
import Students from "./Others/Students";
import Fees from "./Others/Fees";
import Razorpay from "./Razorpay/Razorpay";
import Reports from "./Reports/Reports";
import RecurringInvoices from "./Sales/RecurringInvoices";
import Users from "./Users & Roles/Users";
import CustomerNew from "./SalesNew/CustomerNew";
import ItemsNew from "./Shop/ItemsNew";
import Profile from "./Settings/Profile";
import Templates from "./Settings/Templates";
import Taxes from "./Settings/Taxes";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "./CommonApi";
import InvoiceDetails from "./SalesNew/InvoiceDetails";
import PaymentsMade from "./Purchases/PaymentsMade";
import Setting from "./Settings/Setting";
import BankAccNew from "./Banking/BankAccNew";
import EstimatesNewButton from "./SalesNew/EstimatesNewButton";
import SalesOrderNewButton from "./SalesNew/SalesOrderNewButton";
import DeliveryChallansNew from "./SalesNew/DeliveyChallansNew";
import PaymentRecivedNew from "./SalesNew/PaymentRecivedNew";
import CreditNoteNew from "./SalesNew/CreditNotesNew";
import VendorsNew from "./Purchases/VendorsNew";
import BillsNew from "./Purchases/BillsNew";
import PaymentsMadeNew from "./Purchases/PaymentsMadeNew";
import EwayBills from "./EwayBill/EwayBills";
import EwayBillsNew from "./EwayBill/EwayBillsNew";
import ExpensesNew from "./Purchases/ExpensesNew";
import PurchaseOrderNew from "./Purchases/PurchaseOrderNew";
import RecurringInvoiceNew from "./SalesNew/RecurringInvoiceNew";
import Quotes from "./SalesNew/Quotes";
import QuotesNew from "./SalesNew/QuotesNew";
import GstFilling from "./GST Report/GstFilling";
import GstR1 from "./GST Report/GstR1";
import GstR2 from "./GST Report/GstR2";
// **********Reports Updates***********
import ProfitAndLoss from "./Reports/ProfitAndLoss";
import BalanceSheet from "./Reports/BalanceSheet";
import ProfitAndLossShedual3 from "./Reports/ProfitAndLossShedual3";
import HorizontalProfit from "./Reports/HorizontalProfit";
import SalesCustomer from "./Reports/SalesCustomer";
import SalesItems from "./Reports/SalesItems";
import SalesBySalesperson from "./Reports/SalesBySalesperson";
import CustomerBalance from "./Reports/CustomerBalanace";
import CashStatement from "./Reports/CashStatement";
import HorizontalBalanceSheet from "./Reports/HorizontalBalanceSheet";
import BalanceSheetSchedule from "./Reports/BalanceSheetSchedule";
import BusinessPerfomanceRatios from "./Reports/BusinessPerfomanceRatios";
import MovementOfEquity from "./Reports/MovementOfEquity";
import AgingSummary from "./Reports/AgingSummary";
import ReportsInvoiceDetails from "./Reports/ReportsInvoiceDetails";
import SalesOrderDetails from "./Reports/SalesOrderDetails";
import CustomerBalanceSummary from "./Reports/CustomerBalanceSummary";
import ReceivableSummary from "./Reports/ReceivableSummary";
import ReceivableDetails from "./Reports/ReceivableDetails";
import PaymentsReceived from "./Reports/PaymentsReceived";
import GetPaid from "./Reports/GetPaid";
import VendorBalances from "./Reports/VendorBalances";
import VendorBalancesSummary from "./Reports/VendorBalancesSummary";
import ApAgingSummary from "./Reports/ApAgingSummary";
import BillsDetails from "./Reports/BillsDetails";
import PaymentsMadeReport from "./Reports/PaymentMadesReport";
import AccountTransactions from "./Reports/AccountTransactions";
import AccountTypeSummary from "./Reports/AccountTypeSummary";
import AccountTypeTransactions from "./Reports/AccountTypeTransactions";
import DayBook from "./Reports/DayBook";
import PayableSummary from "./Reports/PayableSummary";
import PayableDetails from "./Reports/PayableDetails";
import CreditNoteDetails from "./Reports/CreditNoteDetails";
import ItemStocks from "./Reports/ItemStocks";
import SalesByInvoice from "./Reports/SalesByInvoice";
// **************** ********************

import Proforma from "./SalesNew/Proforma";
import ProformaNew from "./SalesNew/ProformaNew";
import CRMPage from "./SalesNew/CRMPage";
import QuotesDetails from "./SalesNew/QuotesDetails";
import { CheckBox } from "@mui/icons-material";
import SalesPerson from "./Others/SalesPerson";

class index extends React.Component {
  componentDidMount() {
    const { navigation } = this.props;
    document.title =
      " Zarp  " + navigation.state.routes[navigation.state.index].key;

    /* MOVED FETCHPROFILE API HERE */
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const jwtToken = localStorage.getItem("jwtToken");
    const userId = localStorage.getItem("userId");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const apiUrl = BASE_URL + "/fetchUserProfile";

    const requestData = {
      uniqueId: decryptedUniqueId,
      userId: userId,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestData),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const Data = data.responseMessage;
        this.setState({
          userData: data,
          companyName: Data.companyName,
          businessLocation: Data.businessLocation,
          state: Data.state,
          currency: Data.currency,
          language: Data.language,
          haveGstNumber: Data.haveGstNumber,
          uniqueId: Data.uniqueId,
          setProfile: Data.setup,
          industry: Data.industry,
          address1: Data.address1,
          address2: Data.address2,
          have_gst_number: Data.have_gst_number,
        });
      })
      .catch((error) => {
        console.error("Error fetching user profile data:", error);
      });

    /* NEW */
    // this.fetchData();
    this.checkToken();
  }

  renderBody() {
    const { navigation } = this.props;
    const activeKey = navigation.state.routes[navigation.state.index].key;
    switch (activeKey) {
      case "dashboard": {
        return <Analytical navigation={navigation} />;
      }

      case "Login": {
        return <Login navigation={navigation} />;
      }

      case "PurchaseOrderNew": {
        return <PurchaseOrderNew navigation={navigation} />;
      }

      case "RecurringInvoiceNew": {
        return <RecurringInvoiceNew navigation={navigation} />;
      }

      case "Setting": {
        return <Setting navigation={navigation} />;
      }
      case "BankAccNew": {
        return <BankAccNew navigation={navigation} />;
      }

      case "CustomerNew": {
        return <CustomerNew navigation={navigation} />;
      }
      case "ExpensesNew": {
        return <ExpensesNew navigation={navigation} />;
      }
      case "InvoicesNew": {
        return <InvoicesNew navigation={navigation} />;
      }

      case "Proforma": {
        return <Proforma navigation={navigation} />;
      }

      case "ItemStocks":{
        return <ItemStocks navigation={navigation} />
      }

      case "SalesByInvoice":{
        return <SalesByInvoice navigation={navigation} />
      }

      case "SalesPerson":{
        return <SalesPerson navigation={navigation}/>
      }

      case "GstFilling":{
        return <GstFilling navigation={navigation} />
      }

      case "ProformaNew": {
        return <ProformaNew navigation={navigation} />;
      }
      case "VendorsNew": {
        return <VendorsNew navigation={navigation} />;
      }
      case "EstimatesNewButton": {
        return <EstimatesNewButton navigation={navigation} />;
      }
      case "SalesOrderNewButton": {
        return <SalesOrderNewButton navigation={navigation} />;
      }

      case "PaymentsMadeNew": {
        return <PaymentsMadeNew navigation={navigation} />;
      }

      case "quotes": {
        return <Quotes navigation={navigation} />;
      }

      case "quotesnew": {
        return <QuotesNew navigation={navigation} />;
      }

      case "EwayBills": {
        return <EwayBills navigation={navigation} />;
      }
      
      case "GstR1":{
        return <GstR1 navigation={navigation} />
      }

      case "GstR2":{
        return <GstR2 navigation={navigation} />
      }

      case "EwayBillsNew": {
        return <EwayBillsNew navigation={navigation} />;
      }

      case "DeliveryChallansNew": {
        return <DeliveryChallansNew navigation={navigation} />;
      }
      case "PaymentRecivedNew": {
        return <PaymentRecivedNew navigation={navigation} />;
      }
      case "CreditNoteNew": {
        return <CreditNoteNew navigation={navigation} />;
      }

      case "reports": {
        return <Reports navigation={navigation} />;
      }

      case "paymentsMade": {
        return <PaymentsMade navigation={navigation} />;
      }
      case "QuotesDetails": {
        return <QuotesDetails navigation={navigation} />;
      }

      case "vendors": {
        return <Vendors navigation={navigation} />;
      }
      case "expenses": {
        return <Expenses navigation={navigation} />;
      }
      case "bills": {
        return <Bills navigation={navigation} />;
      }
      case "purchaseOrder": {
        return <PurchaseOrder navigation={navigation} />;
      }

      case "bankacc": {
        return <BankAcc navigation={navigation} />;
      }


      case "ItemsNew": {
        return <ItemsNew navigation={navigation} />;
      }

      case "manageRecipe": {
        return <ManageRecipe navigation={navigation} />;
      }
      case "items": {
        return <Items navigation={navigation} />;
      }
      case "manageSalesPerson": {
        return <ManageSalesPerson navigation={navigation} />;
      }

      case "manageCategory": {
        return <ManageCategory navigation={navigation} />;
      }
      case "manageStock": {
        return <ManageStock navigation={navigation} />;
      }
      case "students": {
        return <Students navigation={navigation} />;
      }
      case "fees": {
        return <Fees navigation={navigation} />;
      }

      // Settings

      case "profile": {
        return <Profile navigation={navigation} />;
      }

      case "templates": {
        return <Templates navigation={navigation} />;
      }

      case "taxes": {
        return <Taxes navigation={navigation} />;
      }

      // Sales Start//
      case "Customers": {
        return <Customers navigation={navigation} />;
      }

      case "Estimates": {
        return <Estimates navigation={navigation} />;
      }

      case "Order": {
        return <Order navigation={navigation} />;
      }

      case "Challans": {
        return <Challans navigation={navigation} />;
      }

      case "Invoices": {
        return <Invoices navigation={navigation} />;
      }
      case "RecurringInvoices": {
        return <RecurringInvoices navigation={navigation} />;
      }

      case "Users": {
        return <Users navigation={navigation} />;
      }

      case "Payment": {
        return <Payment navigation={navigation} />;
      }

      case "Credit": {
        return <Credit navigation={navigation} />;
      }

      case " bankAcc": {
        return <bankAcc navigation={navigation} />;
      }

      case "manageEmployee": {
        return <ManageEmployee navigation={navigation} />;
      }

      case "manageServer": {
        return <ManageServer navigation={navigation} />;
      }

      case "manageProject": {
        return <ManageProject navigation={navigation} />;
      }
      case "managePortfolio": {
        return <ManagePortfolio navigation={navigation} />;
      }
      case "manageProjectData": {
        return <ManageProjectData navigation={navigation} />;
      }
      case "BillsNew": {
        return <BillsNew navigation={navigation} />;
      }

      case "timeSheetLog": {
        return <TimeSheetLog navigation={navigation} />;
      }
 
      case "razorpay": {
        return <Razorpay navigation={navigation} />;
      }

      case "invoicedetails": {
        return <InvoiceDetails navigation={navigation} />;
      }
      // **********Reports Updates***********
      case "HorizontalBalanceSheet": {
        return <HorizontalBalanceSheet navigation={navigation} />;
      }

      case "BalanceSheetSchedule": {
        return <BalanceSheetSchedule navigation={navigation} />;
      }

      case "BusinessPerfomanceRatios": {
        return <BusinessPerfomanceRatios navigation={navigation} />;
      }

      case "MovementOfEquity": {
        return <MovementOfEquity navigation={navigation} />;
      }

      case "AgingSummary": {
        return <AgingSummary navigation={navigation} />;
      }

      case "ReportsInvoiceDetails": {
        return <ReportsInvoiceDetails navigation={navigation} />;
      }

      case "SalesOrderDetails": {
        return <SalesOrderDetails navigation={navigation} />;
      }

      case "CustomerBalanceSummary": {
        return <CustomerBalanceSummary navigation={navigation} />;
      }

      case "ReceivableSummary": {
        return <ReceivableSummary navigation={navigation} />;
      }

      case "ReceivableDetails": {
        return <ReceivableDetails navigation={navigation} />;
      }

      case "PaymentsReceived": {
        return <PaymentsReceived navigation={navigation} />;
      }

      case "GetPaid": {
        return <GetPaid navigation={navigation} />;
      }

      case "VendorBalances": {
        return <VendorBalances navigation={navigation} />;
      }

      case "VendorBalancesSummary": {
        return <VendorBalancesSummary navigation={navigation} />;
      }

      case "ApAgingSummary": {
        return <ApAgingSummary navigation={navigation} />;
      }

      case "BillsDetails": {
        return <BillsDetails navigation={navigation} />;
      }

      case "PaymentsMadeReport": {
        return <PaymentsMadeReport navigation={navigation} />;
      }

      case "AccountTransactions": {
        return <AccountTransactions navigation={navigation} />;
      }

      case "AccountTypeSummary": {
        return <AccountTypeSummary navigation={navigation} />;
      }

      case "AccountTypeTransactions": {
        return <AccountTypeTransactions navigation={navigation} />;
      }

      case "DayBook": {
        return <DayBook navigation={navigation} />;
      }

      case "ProfitAndLoss": {
        return <ProfitAndLoss navigation={navigation} />;
      }

      case "BalanceSheet": {
        return <BalanceSheet navigation={navigation} />;
      }

      case "ProfitAndLossShedual3": {
        return <ProfitAndLossShedual3 navigation={navigation} />;
      }
      case "HorizontalProfit": {
        return <HorizontalProfit navigation={navigation} />;
      }

      case "CashStatement": {
        return <CashStatement navigation={navigation} />;
      }

      case "SalesCustomer": {
        return <SalesCustomer navigation={navigation} />;
      }

      case "SalesItems": {
        return <SalesItems navigation={navigation} />;
      }

      case "SalesBySalesperson": {
        return <SalesBySalesperson navigation={navigation} />;
      }

      case "CustomerBalance": {
        return <CustomerBalance navigation={navigation} />;
      }

      case "PayableSummary": {
        return <PayableSummary navigation={navigation} />;
      }

      case "PayableDetails": {
        return <PayableDetails navigation={navigation} />;
      }

      case "CreditNoteDetails": {
        return <CreditNoteDetails navigation={navigation} />;
      }

      case "CRMPage": {
        return <CRMPage navigation={navigation} />;
      }
      // **************************

      default:
        return null;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      userData: "",
      companyName: "",
      businessLocation: "India",
      state: "Maharashtra",
      country: "India",
      currency: "INR- Indian Rupee",
      language: "",
      haveGstNumber: "",
      uniqueId: "",
      setProfile: this.setup,
      setting: true,
      profile: false,
      showDetails: true,
      showInputs: false,
      toggleValue: false,
      showGSTInput: false,
      industry: "",
      address1: "",
      address2: "",
      haveGstNumber: "",
      isChecked: false,
      gstError: "",
    };
    // this.state.token = localStorage.getItem('jwtToken');
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    {
      /* NEW */
    }

    this.handleFormSubmit = (event) => {
      event.preventDefault();
      if (this.state.isChecked && !this.state.haveGstNumber) {
        this.setState({ gstError: "Please enter a GST number." });
        return; // Prevent form submission
      } else {
        this.setState({ gstError: "" });
      }
      const encryptedUniqueId = localStorage.getItem("uniqueId1");
      const decryptedUniqueId = AES.decrypt(
        encryptedUniqueId,
        "1234567812345678"
      ).toString(enc.Utf8);
      const jwtToken = localStorage.getItem("jwtToken");

      const updatedData = {
        companyName: this.state.companyName,
        businessLocation: this.state.country,
        state: this.state.state,
        currency: this.state.currency,
        language: this.state.language,
        uniqueId: decryptedUniqueId,
        haveGstNumber: this.state.haveGstNumber,
        industry: this.state.industry,
        country: this.state.country,
      };

      fetch(BASE_URL + "/orgnization/Create", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(updatedData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const requestBody = {
            uniqueId: decryptedUniqueId,
            numbers: "000001",
          };

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify(requestBody),
          };

          return fetch(BASE_URL + "/initiateNumberList", options);
        })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.responseMessage);
            });
          }
          return response.json();
          this.setState({ loading: false });
        })
        .then((data) => {
          this.setState({ setProfile: true });
        })
        .catch((error) => {
          console.error("Error updating user profile:", error);
        });
    };
  }
  handleCheckboxChange(event) {
    this.setState({
      isChecked: event.target.checked,
      haveGstNumber: "", // Reset the input field when checkbox is unchecked
    });
  }

  /* NEW */
 
  checkToken = () => {
    // this.state.token = localStorage.getItem('jwtToken');
    console.log("this is value of the toke is ",this.state.token)
  };

  state = {
    toggleValue: false, // Assuming you have a state to manage the toggle value
  };

  handeltoggle = () => {
    this.setState((prevState) => ({
      toggleValue: !prevState.toggleValue,
      showGSTInput: !prevState.showGSTInput,
    }));
  };
  extraInput = () => {
    this.setState({ showDetails: false, showInputs: true });
  };

  handleRightBar1 = () => {
    this.setState({ setting: true, profile: false });
  };

  handleRightBar2 = () => {
    this.setState({ setting: false, profile: true });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  logoutSectionStyle = {
    position: "fixed",
    top: 0,
    right: 0,
    padding: "10px",
    backgroundColor: "#f0f0f0", // Optional: Set a background color
  };

  render() {
    const { navigation } = this.props;
    let companyName = "";
    let businessLocation = "";
    let state = "";
    let currency = "INR- Indian Rupee";
    let language = "";
    let country = "";

    if (this.state.userData) {
      const Data = this.state.userData.responseMessage;
      companyName = Data.companyName;
      businessLocation = Data.country;
      state = Data.state;
      currency = Data.currency;
      language = Data.language;
      country = Data.country;
    }
    const { toggleValue, showGSTInput } = this.state;
    const gstStatus = toggleValue ? "Yes" : "No";
    return (
      <>
        {this.state.setProfile == "1" &&  (
          <div>
            <Navbar
              handleBar1={this.handleRightBar1}
              handleBar2={this.handleRightBar2}
              navigation={navigation}
            />
            <Leftbar navigation={navigation} />
            <div id="main-content">{this.renderBody()}</div>
          </div>
        )}
        {this.state.setProfile == "0" &&  (
          <div>
            <form onSubmit={this.handleFormSubmit}>
              <div
                id="wrapper"
                style={{
                  margin: "0",
                  padding: "0",
                }}
              >
                <div
                  className=" setup-body mx-auto bg-white shadow-sm  scrollbar"
                  style={{
                    maxWidth: "600px",
                    height: "auto",
                    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                    borderRadius: "6px",
                    margin: "1% auto",
                  }}
                >
                  <div
                    className="row mx-auto my-auto pt-4 pb-5"
                    style={{ maxWidth: "400px", color: "gray" }}
                  >
                    <div className="col-md-12 mb-3">
                      <div className="" style={{ marginLeft: "-15px" }}>
                        <img src={newzerosblackmainmenu} width="40%" />
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <h4
                        className="text-regular"
                        style={{
                          fontSize: "20px",
                          fontWeight: "-moz-initial",
                          color: "black",
                        }}
                      >
                        Set up your organization profile
                      </h4>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <p className="" style={{ fontSize: "14px" }}>
                          Organization Name
                        </p>
                        <input
                          className="form-control mt-2"
                          style={{
                            fontSize: "15px",
                            width: "100%",
                            backgroundColor: "#f6f6f6",
                          }}
                          placeholder="Organization Name"
                          type="text"
                          name="companyName"
                          id="companyName"
                          value={this.state.companyName}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <p className="" style={{ fontSize: "14px" }}>
                          Industry
                        </p>
                        <select
                          name="industry"
                          style={{
                            fontSize: "15px",
                            backgroundColor: "#f6f6f6",
                            borderRadius: "7px",
                          }}
                          // id="state"
                          className="form-control mt-2"
                          // value={state}
                          value={this.state.industry}
                          onChange={this.handleInputChange}
                        >
                          <option>Select Industry ...</option>
                          <option value="Agency or Sales House">
                            Agency or Sales House
                          </option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="Art and Design">Art and Design</option>
                          <option value="Automotive">Automotive</option>
                          <option value="Construction">Construction</option>
                          <option value="Consulting">Consulting</option>
                          <option value="Consumer Packaged Goods">
                            Consumer Packaged Goods
                          </option>
                          <option value="Education">Education</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Entertainment">Entertainment</option>
                          <option value="Financial Services">
                            Financial Services
                          </option>
                          <option value="Food Services(Restaurant/Fast Food)">
                            Food Services(Restaurant/Fast Food)
                          </option>
                          <option value="Gaming">Gaming</option>
                          <option value="Government">Government</option>
                          <option value="Health Care">Health Care</option>
                          <option value="Interior Design">
                            Interior Design
                          </option>
                          <option value="Internal">Internal</option>
                          <option value="Legal">Legal</option>
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Marketing">Marketing</option>
                          <option value="Mining and Logistics">
                            Mining and Logistics
                          </option>
                          <option value="Non-Profit">Non-Profit</option>
                          <option value="Publishing and Web Media">
                            Publishing and Web Media
                          </option>
                          <option value="Real Estate">Real Estate</option>
                          <option value="Retail(E-Commerce and Office)">
                            Retail(E-Commerce and Office){" "}
                          </option>
                          <option value="Services">Services</option>
                          <option value="Technology">Technology</option>
                          <option value="Telecommunications">
                            Telecommunications
                          </option>
                          <option value="Travel/Hospital">
                            Travel/Hospital
                          </option>
                          <option value="Web Designing">Web Designing</option>
                          <option value="Writers">Writers</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <p className="" style={{ fontSize: "14px" }}>
                          Country
                        </p>
                        <select
                          name="country"
                          style={{
                            fontSize: "15px",
                            backgroundColor: "#f6f6f6",
                            borderRadius: "7px",
                          }}
                          className="form-control mt-2"
                          value={this.state.country}
                          onChange={this.handleInputChange}
                        >
                          <option value="India">India</option>
                          <option value="USA">USA</option>
                          <option value="Canada">Canada</option>
                          <option value="UK">UK</option>
                          <option value="Australia">Australia</option>
                          <option value="China">China</option>
                          <option value="Japan">Japan</option>
                          <option value="Germany">Germany</option>
                          <option value="France">France</option>
                          <option value="Italy">Italy</option>
                          <option value="Brazil">Brazil</option>
                          <option value="Russia">Russia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Argentina">Argentina</option>
                          <option value="Spain">Spain</option>
                          <option value="Canada">Canada</option>
                          <option value="UK">UK</option>
                          <option value="Italy">Italy</option>
                          <option value="France">France</option>
                          {/* Add more country options here */}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12" style={{ fontSize: "14px" }}>
                      <p className="">State / Union Territory</p>
                      <select
                        name="state"
                        style={{
                          fontSize: "15px",
                          backgroundColor: "#f6f6f6",
                          borderRadius: "7px",
                        }}
                        id="state"
                        className="form-control mt-2"
                        // value={state}
                        value={this.state.state}
                        onChange={this.handleInputChange}
                      >
                        <option>Maharashtra</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadar and Nagar Haveli">
                          Dadar and Nagar Haveli
                        </option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>

                    <div
                      className="col-md-12 mt-4 mb-3"
                      style={{ fontSize: "14px" }}
                    >
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          id="agreement"
                          name="agreement"
                          value="agreed"
                          style={{
                            zoom: "1",
                            marginRight: "3px",
                            backgroundColor: "#f6f6f6",
                          }}
                          onChange={this.handleCheckboxChange}
                          checked={this.state.isChecked}
                        />
                        <label
                          for="agreement"
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                            margin: "0",
                            color: "#000",
                          }}
                        >
                          This business is GST registered
                        </label>
                      </div>
                      {this.state.isChecked && (
                        <div>
                          <input
                            className="form-control mt-2"
                            style={{
                              fontSize: "15px",
                              width: "100%",
                              backgroundColor: "#f6f6f6",
                            }}
                            placeholder="Enter GST Number"
                            type="text"
                            name="haveGstNumber"
                            id="haveGstNumber"
                            value={this.state.haveGstNumber}
                            onChange={this.handleInputChange}
                          />
                          {this.state.gstError && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {this.state.gstError}
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 mb-4">
                      <button
                        className="getstarted btn btn-success"
                        style={{
                          width: "100%",
                          backgroundColor: "#419AF9",
                          fontSize: "16px",
                          padding: "10px",
                          borderRadius: "7px",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "#000")} // Change color on hover
                        onMouseLeave={(e) => (e.target.style.color = "#fff")} // Revert color on mouse leave
                        onClick={this.handleFormSubmit}
                      >
                        Get Started
                      </button>
                    </div>
                    <div className="col-md-12 text-center">
                      <p>
                        You can edit these preferences later from the Settings.
                      </p>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </form>
          </div>
        )}
      </>
    );
  }
}
export default index;
